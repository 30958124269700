import { LinkCC } from "@src/shared_modules/router";
import * as React from "react";

export default function Index() {
    return (
        <section className="bg-navy p-25-px d-flex flex-grow-1 align-items-center justify-content-center flex-column tw-gap-8 tw-leading-relaxed">
            <h1 className="text-white text-center font-size-36-px font-primary-bold pb-25-px tw-max-w-6xl">
                Uh-oh, seems like we can't find the page that you're looking
                for.
            </h1>
            <LinkCC
                className="btn-lg btn-secondary font-secondary-bold font-size-16-px"
                href="/login"
            >
                Take Me Home
            </LinkCC>
        </section>
    );
}
